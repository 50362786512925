@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.dk-addClientMain{
    font-family: "Poppins",sans-serif!important;
    .dk-signupMain{
        background: #fff;
        padding: 15px 0;
        margin-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .d-signUpFlex{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            h3.title{
                text-align: center;
                font-size: 22px;
                font-weight: 700;
                color: #333;
                width: 100%;
                margin-bottom: 18px;
                text-transform: uppercase;
            }
            h4{
                display: block;
                width: 100%;
                margin-top: 0;
                margin-bottom: 20px;
                font-size: 15px;
                text-align: center;
            }
        }
        .dk-signupContainer{
            width: 100%;
            max-width: 450px;
            background: #eaeaea;
            padding: 10px 35px 20px;
            border-radius: 5px;
            border: 1px solid #dcdcdc;
            form{
                width: 100%;
                max-width: 100%;
                margin: 15px auto 8px;
                label{
                    width: 100%;
                    position: relative;
                    margin-left: 1px;
                    margin-bottom:2px;
                    color: #333;
                    font-weight: 400;
                }
                label.dk-agreeTerms{
                    input[type=checkbox]{
                        position: relative;
                        top: 0;
                        margin-right: 5px;
                        width: 15px;
                    }
                }
                label.dk-infoDesc{
                    span{
                        display: inline-block;
                        width: 35px;
                        height: 38px;
                        background: #d9dfe7;
                        text-align: center;
                        line-height: 38px;
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: #7b7b7b;
                        border: 1px solid #cecece;
                        border-radius: 0 3px 3px 0;
                    }
                }
                input{
                    border-radius: 0;
                    height: 38px;
                    box-shadow: none !important;
                    border:1px solid #ddd !important;
                    font-family: "Poppins",sans-serif!important;
                }
                input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                    color:#bbb;
                }
                input::-moz-placeholder { /* Firefox 19+ */
                    color:#bbb;
                }
                input:-ms-input-placeholder { /* IE 10+ */
                    color:#bbb;
                }
                input:-moz-placeholder { /* Firefox 18- */
                    color:#bbb;
                }
                select{
                    height: 38px;
                    border-radius: 3px;
                    border: 1px solid #ddd!important;
                    box-shadow: none!important;
                    font-weight: 400;
                    font-size: 14px;
                    background-color: #fff;
                }
                span.domainAppendSpan.dk-stepOneDomain{
                    padding-right: 0;
                    background: #eaeaea !important;
                }
                span.domainAppendSpan{
                    right: 0;
                    top: 0;
                    background: #fff !important;
                    height: 38px;
                    font-size: 13px;
                    font-weight: 400;
                    padding: 0 10px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    line-height: 40px;
                    border: 1px solid #d6d6d6!important;
                    border-left: none!important;
                    display: flex;
                    align-items: center;
                    svg{
                        color: #928c8c;
                    }
                    i{
                        display: inline-block;
                        margin-left: 10px;
                        color: #7b7b7b;
                        width: 35px;
                        height: 36px;
                        background: #d9dfe7;
                        text-align: center;
                        line-height: 39px;
                        border-left: 1px solid #cecece;
                        border-radius: 0 3px 3px 0;
                    }
                }
                .dk-submitBtn{
                    a{
                        min-width: 170px;
                        color: #fff;
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 16px;
                        outline: none;
                        margin-top: 5px;
                        max-width: 180px;
                        border-radius: 30px;
                        padding: 12px 15px;
                        background-color: #2d96b6;
                        display: inline-block;
                        &:hover{
                            text-decoration: none;
                            background: #5e737d;
                            transition: all .5s;
                        }
                    }
                    .dk-addCliBtnCon{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        button.dk-addCliBtn{
                            min-width: 170px;
                            color: #fff;
                            text-transform: uppercase;
                            font-weight: 500;
                            font-size: 16px;
                            outline: none;
                            margin-top: 5px;
                            max-width: 180px;
                            border-radius: 30px;
                            padding: 8px 15px;
                            background-color: #2d96b6;
                            display: inline-block;
                            font-family: "Poppins", sans-serif !important;
                            .MuiButton-label{
                                .MuiButton-endIcon.MuiButton-iconSizeMedium{
                                    margin-left: 2px;
                                }
                            }
                        }
                        button.dk-addCliBtn:hover {
                            text-decoration: none;
                            background: #5e737d;
                            transition: all .5s;
                        }
                    }
                }
            }
        }
    }
}

.tooltip.show .tooltip-inner {
    padding: 8px 10px;
    text-align: justify;
    line-height: 16px;
    max-width: 220px;
    background-color:#2d96b6;
}

.tooltip.show .arrow::before{
    border-top-color:#2d96b6;
}
.dk-signupMain .dk-signupContainer form label .visible.menu {
    border-color: #ddd!important;
}
.dk-signupMain .dk-signupContainer form label .selection.dropdown, .dk-signupMain .dk-signupContainer form label .selection.dropdown:hover {
    border: 1px solid #ddd!important;
}

input::-webkit-credentials-auto-fill-button {
    display: none;
    opacity: 0;
    visibility: hidden;
    background-color: transparent;
}
input::-webkit-textfield-decoration-container {
    display: none; /* or whatever styling you want */
}

input::-webkit-autofill,
input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button{
    display: none;
    opacity: 0;
    visibility: hidden;
    background-color: transparent;
}