.dk-deleteModalMain{
    .modal-dialog{
        min-width: 30%;
        max-width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin: 0 auto;
        .modal-content{
            border-radius: 5px;
            .modal-body{
                padding: 20px 25px;
                .dk-deleteModal-body{
                    p{
                        font-size: 15px;
                        color: #e2445b;
                        font-weight:500;
                        padding: 15px 0;
                        display: block;
                    }
                    .d-flex{
                        button{
                            border: 1px solid #333;
                            color: #333;
                            background: transparent;
                            display: inline-block;
                            width: 100%;
                            max-width: 90px;
                            border-radius: 5px;
                            padding: 7px 0;
                            transition: all .5s;
                            outline: none;
                            font-weight:500;
                            &:hover{
                                background: #333;
                                border-color: #333;
                                transition: all 0.5s;
                                color: #fff;
                            }
                        }
                        button.dk-yesBtn{
                            background: #e2445b;
                            border: 1px solid #e2445b;
                            color: #fff;
                            margin-left: 10px;
                            &:hover{
                                background: #333;
                                border-color: #333;
                                transition: all 0.5s;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}