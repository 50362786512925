@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');

html, body{
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  color: #333 !important;
}

body{
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100vh;
  height: auto !important;
}

html {
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
}

.dk-tableEditBtn{
  background: #fee69c;
  color: #333;
  outline: 0;
  box-shadow: none;
  text-decoration: none;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  line-height: 32px;
}

.dk-tableEditBtn:hover{
  color:#333;
}

.dk-tableDeleteBtn{
  background: #E2445B;
  color: #333;
  outline: 0;
  box-shadow: none;
  text-decoration: none;
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  line-height: 32px;
  color: #fff;
}

.dk-tableDeleteBtn:hover{
  color:#fff;
}
html::-webkit-scrollbar {
  width: 0px;
}
 
html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}
 
html::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 0 solid slategrey;
}