.dk-commentModalMain{
    .modal-dialog{
        position: fixed;
        margin: auto;
        width: 100%;
        min-width: 60% !important;
        max-width: 60% !important;
        height: 100%;
        right:-60%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }
    .modal-content{
        border-radius: 0;
        box-shadow: none;
        height: 100vh;
        .modal-body{
            background: #dee1e3;
            border-radius: 0 0 5px 5px;
            padding-bottom: 1.25rem;
        }
    }
    .modal-header{
        background: #26597d;
        padding: 8px 10px;
    }
    .modal-title{
        margin-top: 0;
        font-size: 17px;
        font-weight: 500;
        font-family: Roboto,sans-serif;
        text-align: center;
        width: 100%;
        text-transform: uppercase;
        color: #fff;
    }
    .close{
        font-size: 1.8em;
        padding: 0px;
        right: 25px;
        position: absolute;
        top: 24px;
        opacity: 1;
        color: #fff !important;
        text-shadow: none;
        outline: none;
        font-weight: normal;
    }
}

.modal.right.fade .modal-dialog {
    right: 0;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.comments-container{
    width: 100%;
    .scrollBox{
        overflow: auto;
        height: calc(100vh - 215px);
        width: 100%;
        ul.comments-list{
            padding-left: 0;
            margin-top: 1rem;
            position: relative;
            list-style: none;
            float: left;
            width: 100%;
            &::before{
                content: '';
                width: 2px;
                height: 100%;
                background: #c7cacb;
                position: absolute;
                left: 25px;
                top: 0;
            }
            li{
                margin-bottom: 15px;
                display: block;
                position: relative;
                float: left;
                width: 100%;
                .comment-main-level{
                    .comment-avatar{
                        width: 50px;
                        height: 50px;
                        position: relative;
                        z-index: 99;
                        float: left;
                        border: 3px solid #FFF;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        border-radius: 4px;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .comment-box{
                        width: 90%;
                        float: right;
                        position: relative;
                        box-shadow: 0 1px 1px rgba(0,0,0,0.15);
                        padding:0;
                        &::before{
                            border-width: 11px 13px 11px 0;
                            border-color: transparent rgba(0,0,0,0.05);
                            left: -12px;
                        }
                        &::after{
                            content: '';
                            height: 0;
                            width: 0;
                            position: absolute;
                            display: block;
                            border-width: 10px 12px 10px 0;
                            border-style: solid;
                            border-color: transparent #FCFCFC;
                            top: 8px;
                            left: -11px;
                        }
                        .comment-head{
                            background: #FCFCFC;
                            padding:7px 12px;
                            border-bottom: 1px solid #E5E5E5;
                            overflow: hidden;
                            -webkit-border-radius: 4px 4px 0 0;
                            -moz-border-radius: 4px 4px 0 0;
                            border-radius: 4px 4px 0 0;
                            .d-none {
                                display: none!important;
                            }
                            span{
                                float: left;
                                color: #999;
                                font-size: 13px;
                                position: relative;
                                .date{
                                    margin-right: 10px;
                                    display: inline;
                                    font-size: 14px;
                                    font-weight: 400;
                                    i{
                                        display: inline-block;
                                        margin-right:8px;
                                    }
                                }
                            }                       
                        }
                        .headIcons{
                            i{
                                margin-left: 10px;
                                display: inline-block;
                                color: #666;
                                cursor: pointer;
                                &:hover{
                                    color: #03658c;
                                    transition: all 0.5s;
                                }
                            }
                        }
                        .comment-content{
                            background: #FFF;
                            padding: 12px;
                            font-size: 14px;
                            color: #595959;
                            -webkit-border-radius: 0 0 4px 4px;
                            -moz-border-radius: 0 0 4px 4px;
                            border-radius: 0 0 4px 4px;
                            font-weight: 400;
                        }
                        
                    }
                }
            }
            .comments-list-reply{
                float: right;
                width: 96%;
                margin-top: 15px;
                li{
                    position: relative;
                    &::before{
                        content: '';
                        width: 60px;
                        height: 2px;
                        background: #c7cacb;
                        position: absolute;
                        top: 25px;
                        left: -60px;
                    }
                }
            }
        }
    }
    .dk-commentReplyBox{
        width: 100%;
        margin-top: 10px;
        position: absolute;
        bottom: 25px;
        left: 0;
        width: 100%;
        padding: 0 25px;
        textarea{
            border:1px solid #ccc !important;
            box-shadow: none !important;
            height:70px;
            resize: vertical;
            background: #fff;
        }
        .dk-commentSubmitBtn{
            display: block;
            margin-top: 10px;
            text-align: center;
            button{
                background: #26597d;
                display: inline-block;
                padding: 10px 25px;
                text-transform: uppercase;
                color: #fff;
                font-weight:500;
                font-size: 14px;
                outline: none;
                border:none;
                border-radius: 3px;
                &:hover{
                    background: #234158;
                    transition: all 0.5s;
                }
            }
        }
    }
    
}