.dk-navbarMain{
    background:#5c737d;
    padding:80px 20px 0;
    width: 100%;
    .dk-navbarMenu{
        width: 100%;
        max-width: 1600px;
        margin: auto;
        ul{
            display: flex;
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                list-style-type: none;
                width: 100%;
                max-width: 9.9%;
                text-align: center;
                &:first-child{
                    a{
                       border-radius:5px 0 0 0;
                    }
                }
                &:last-child{
                    a{
                        border-right: 1px solid #80868a;
                        border-radius: 0 5px 0 0;
                    }
                }
                a{
                    width: 100%;
                    background-color: #2e536f;
                    display: flex;
                    color: #fff;
                    font-weight: 500;
                    font-size: 14px;
                    padding: 12px 10px;
                    border: 1px solid #80868a;
                    border-right: none;
                    justify-content: center;
                    &:hover{
                        text-decoration:none;
                    }
                    span.dk-badge{
                        background:#3f9eba;
                        padding: 0;
                        border-radius: 3px;
                        display: inline-block;
                        width: 19px;
                        height: 19px;
                        font-size: 12px;
                        line-height: 19px;
                        margin-right: 5px;
                        color: #fff;
                    }
                    span.dk-badge.blueColor{
                        background-color: #ec407a;
                        color: #fff;
                    }
                    span.dk-badge.red{
                        background:#ff3547;
                        color: #fff;
                    }
                }
                .dropdown-toggle{
                    position: relative;
                    &::after{
                        content: '';
                        border-top: .4em solid;
                        border-right: 0.4em solid transparent;
                        border-left: .4em solid transparent;
                        margin-top: 9px;
                        margin-left: 8px;
                        color: #fff;
                    }
                }
                a.active{
                    background: #eaeaea;
                    color: #333;
                    border-color:#eaeaea;
                }
            }
        }
    }
}