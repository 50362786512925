.dk-settingsUserMain{
    padding:30px 20px;
    .dk-settingUserTabs{
        .nav-tabs{
            border:none;
            a{
                background: #fff;
                color: #333333;
                font-size: 14px;
                border: 1px solid #dbdbdb;
                border-top-left-radius: .25rem;
                border-top-right-radius: .25rem;
                margin-right:5px;
                &:nth-child(04){
                    margin-left: auto;
                }
                &:nth-child(01){
                    width:90px;
                    text-align: center;
                }
                &:last-child{
                    margin-right:0;
                }
            }
            a.active{
                background: #2e97b5;
                border-color: #2e97b5;
                color: #ffffff;
            }
        }
        .dk-userTableMain{
            margin-top:0;
            table{
                width:100%;
                margin-bottom: 0;
                tr{
                    th{
                        border-bottom-width: 0;
                        border-bottom: none;
                        background: #2e97b5;
                        color: #fff;
                        text-align: center;
                        font-size: 14px;
                        vertical-align: middle;
                        white-space: nowrap;
                        &:nth-child(01){
                            width: 90px;
                            min-width: 90px;
                        }

                        &:nth-child(02){
                            text-align: left;
                            width:30%;
                        }
                    }
                    td{
                        vertical-align: middle;
                        background-color: #F5F5F5;
                        white-space: nowrap;
                        color: #333;
                        padding: 0.35em 1em;
                        font-weight: 500;
                        font-size: 14px;
                        text-align: center;
                        font-weight:500;
                        &:nth-child(02){
                            text-align: left;
                        }
                        .dk-userImg{
                            img{
                                width: 35px;
                                height: 35px;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }
                        .dk-userStatusBtn{
                            span{
                                color: #fff;
                                padding:0;
                                font-size: 14px;
                                border: none;
                                border-radius: 0;
                                background-color: #03c09a;
                            }
                            span.inactive{
                                background-color:#E2445B;
                            }
                            .dropdown{
                                .dropdown-toggle{
                                    background-color: #03c09a;
                                    border-color: #03c09a;
                                    width: 95px;
                                    box-shadow: none !important;
                                }
                                .dropdown-menu{
                                    padding: 0;
                                    min-width: 95px;
                                    a{
                                        padding: 6px 12px;
                                        border-bottom: 1px solid #ddd;
                                        &:last-child{
                                            border-bottom:none;
                                        }
                                    }
                                }
                            }
                        }
                        .dk-userStatusBtn.dk-userActiveBtn{
                            span{
                                display: inline-block;
                                width: 95px;
                                padding: 7px 0;
                                border-radius: 3px;
                            }
                        }
                        .dk-userStatusBtn.dk-userActiveBtn.dk-inactiveBtn{
                            span{
                                background: #E2445B;
                            }
                        }
                        span.emailId{
                            font-size: 12px;
                            display: block;
                        }
                        .dk-userEditBtn{
                            button{
                                background-color: #007bff;
                                color: #fff !important;
                                padding:6px 18px;
                                border: none;
                                border-radius:3px !important;
                                outline: none;
                                font-size: 14px;
                            }
                        }
                        .dk-userDeleteBtn{
                            button{
                                background-color: #E2445B;
                                color: #fff !important;
                                padding:6px 18px;
                                border: none;
                                border-radius: 0 !important;
                                outline: none;
                                font-size: 14px;
                            }
                        }
                        .dk-roleDropdown.dropdown{
                            .dropdown-toggle{
                                width: 125px;
                                background:#eaeaea;
                                border:1px solid #ddd;
                                color: #333;
                                box-shadow: none !important;
                            }
                            .dropdown-menu{
                                padding: 0;
                                min-width:115px;
                                a{
                                    padding:6px 12px;
                                    border-bottom: 1px solid #ddd;
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                }
                            }
                        }
                        .dk-userStatusBtn.dk-inactiveBtn{
                            .dropdown{
                                .dropdown-toggle{
                                    background: #E2445B;
                                    border-color: #E2445B;
                                    span{
                                        background: #E2445B;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .dk-userSettingAddnew-container{
            background-color:#eaeaea;
            padding:70px 25px 50px;
            border-radius: 0 0 5px 5px;
        }
        .dk-userSetting-addNew{
            width: 100%;
            max-width: 600px;
            margin: auto;
            margin-top: 1rem;
            .title{
                margin-top: 0.5rem;
                padding:8px 10px;
                background-color: #3c97b5;
                color: #fff;
                border-radius:5px 5px 0 0;
                width: 100%;
                text-align: center;
                font-weight: bold;
                font-size: 18px;
            }
            form{
                border: 1px solid #ddd;
                padding:20px 25px;
                border-radius: 0 0 5px 5px;
                background: #fff;
                label{
                    width:100%;
                    input{
                        border-radius: 0;
                        height: 35px;
                        background: #f2f2f2;
                        border:1px solid #ddd !important;
                        box-shadow: none !important;
                    }
                    select{
                        border-radius: 0;
                        height: 35px;
                        background: #f2f2f2;
                        border:1px solid #ddd !important;
                        box-shadow: none !important;
                    }
                }
                .dk-userAddBtn{
                    background:#3c97b5;
                    display: inline-block;
                    padding:8px 25px;
                    border:none;
                    color: #fff;
                    font-size: 14px;
                    border-radius: 5px;
                    &:hover{
                        background:#2e536f;
                        transition: all 0.5s;
                    }
                }
            }
        }
        .dk-userEditMain{
            .admin-edit-profile{
                box-shadow: none;
                padding: 25px;
                background-color: #d9d9d9;
                width: 100%;
                margin: auto;
                margin-top: 15px;
                .profile-photo{
                    figure{
                        margin-right: 10px;
                        width: 130px;
                        height: 130px;
                        border: 1px solid #999;
                        img{
                            width: 100%;
                        }
                    }
                    .dk-userChoosFile{
                        position: relative;
                        width: 100%;
                        max-width: 120px;
                        input{
                            width: 120px;
                            height: 35px;
                            position: relative;
                            z-index: 9;
                            opacity: 0;
                            cursor: pointer;
                        }
                        span{
                            width: 100%;
                            max-width: 120px;
                            height: 35px;
                            cursor: pointer;
                            z-index: 1;
                            background:#26597d;
                            color: #fff;
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            right: 0;
                            text-align: center;
                            line-height: 35px;
                        }
                    }
                }
                .dk-userEditForm{
                    form{
                        label{
                            width: 100%;
                            input{
                                border-radius: 0;
                                background: #f5f2f2;
                                border:1px solid #ddd !important;
                                box-shadow: none !important;
                                height: 35px;
                            }
                            select{
                                border-radius: 0;
                                background: #f5f2f2;
                                border:1px solid #ddd !important;
                                box-shadow: none !important;
                            }
                        }
                        label.dk-userDate{
                            position: relative;
                            input{
                                padding-right: 45px;
                            }
                            span{
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 35px;
                                height: 35px;
                                background: #dedede;
                                text-align: center;
                                line-height: 35px;
                            }
                        }
                        label.dk-userPassword{
                            position: relative;
                            input{
                                padding-right: 45px;
                            }
                            span{
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: 35px;
                                height: 35px;
                                background: #dedede;
                                text-align: center;
                                line-height: 35px;
                            }
                        }
                        .dk-userSbtitle{
                            display: block;
                            width: 100%;
                            font-size: 16px;
                            margin: 10px 16px 30px;
                            background: #f2f2f2;
                            padding: 12px 0;
                            text-align: center;
                        }
                    }
                }
            }
            .dk-userSaveprofileBtn{
                background-color: #64686f;
                border-color: #64686f;
                color: #fff;
                border: none;
                padding: 6px 20px;
                margin: 30px 0 10px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 600;
                cursor: pointer;
                outline: none;
                &:hover{
                    background-color: #2d96b6;
                    border-color: #2d96b6;
                    transition: all 0.5s;
                }
            }
        }
    }
}