header{
    padding:8px 20px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 99;
    .dk-headerMain{
        .dk-logo{
            a{
                display: inline-block;
                width: 100%;
                max-width: 140px;
            }
        }
        .dk-headTitle{
            display: inline-block;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 18px;
            font-weight:600;
        }
        .dk-headRight{
            .dropdown{
                .dropdown-toggle.btn{
                    padding: 0;
                    background: transparent !important;
                    box-shadow: none !important;
                    border:none;
                    &::after{
                        display: none;
                    }
                    span{
                        img{
                            width:35px;
                            height:35px;
                            border-radius: 50%;
                            margin-right:8px;
                        }
                    }
                }
                .dropdownIcon{
                    svg{
                        color: #333;
                        display: inline-block;
                        border: 1px solid #ccc;
                        border-radius: 50%;
                        width: 20px;
                        height: 20px;
                        font-size: 26px;
                    }
                }
                .dropdown-menu{
                    padding: 0;
                    border: none;
                    left: initial;
                    right: 0px;
                    top: 57px;
                    border-radius: 5px;
                    a{
                        padding: 6px 15px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}