.dk-tablePagination{
    margin-top: 15px;
    ul.MuiPagination-ul{
        margin: 0;
        padding: 0;
        li{
            list-style-type: none;
            button{
                display: inline-block;
                width: 30px;
                height: 30px;
                line-height: 30px;
                background: #f2f2f2;
                border: 1px solid #ddd;
                border-right: none;
                color: #333;
                font-weight:600;
                text-align: center;
                margin: 0;
                border-radius: 0;
                padding: 0;
                outline: none;
                &:hover{
                    background:#2e536f;
                    border-color: #2e536f;
                    text-decoration: none;
                    color: #fff;
                    transition: all 0.5s;
                }
                svg{
                    font-size:23px;
                }
            }
            button.Mui-selected{
                background:#2e536f;
                border-color: #2e536f;
                color: #fff;
            }
            button.Mui-disabled{
                opacity:0.7;
            }
            &:first-child{
                button{
                    border-radius: 3px 0 0 3px;
                }
            }
            &:last-child{
                button{
                    border-right: 1px solid #ddd;
                    border-radius:0 0 3px 3px;
                }
            }
        }
    }
}