.dk-adminLoginMain{
    // background: #EAEAEA;
    background: url('../../../assets/images/login_bg.jpg') no-repeat;
    width: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .dk-adminLogin{
        width: 100%;
        max-width: 450px;
        margin: auto;
        height: 450px;
        background: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        border: 1px solid #dcdcdc;
        .dk-adminLogo{
            width: 100%;
            max-width: 140px;
            margin: auto;
            margin-bottom:30px;
        }
        h3.title{
            text-align: center;
            font-weight:400;
            font-size: 28px;
        }
        .dk-adminLoginLogo{
            display: block;
            width: 100%;
            max-width: 145px;
            text-align: center;
            margin: auto;
            margin-bottom: 10px;
        }
        .dk-passwordeye{
            position: relative;
            span{
                display: inline-block;
                width:35px;
                height:37px;
                position: absolute;
                right: 0;
                top: 0;
                border-left: 1px solid #ddd;
                text-align: center;
                line-height:37px;
                color: #828282;
            }
        }
        form{
            width: 100%;
            max-width: 330px;
            margin: auto;
            margin-top: 20px;
            label{
                width: 100%;
                margin-bottom: 8px;
                a{
                    font-size: 13px;
                    display: block;
                }
                input{
                    border-radius:3px;
                    height: 38px;
                    font-size: 14px;
                    font-weight:400;
                    box-shadow: none !important;
                    border:1px solid #ddd !important;
                }
                .dk-submitBtn{
                    display: inline-block;
                    background: #2d96b6;
                    border-radius: 5px;
                    padding:7px 25px;
                    text-transform: uppercase;
                    color: #fff;
                    border: none;
                    font-weight: 500;
                    text-decoration: none;
                    &:hover{
                        background: #325f7d;
                        transition: all 0.5s;
                    }
                }
            }
        }
    }
}

.dk-loginHeader{
    padding:8px 20px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 99;
    a{
        display: inline-block;
        width: 100%;
        max-width: 140px;
    }
}
