.dk-appsStandardMain{
    padding: 35px 0 0;
    width: 100%;
    max-width: 1600px;
    margin: auto;
    .dk-container-apps{
        overflow-y: auto;
        overflow-x: hidden;
        *height: calc(100vh - 201px);
        display: flex;
        padding: 0 20px;
    }
    .dk-container-apps::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 0;
        background-color: #F5F5F5;
    }

    .dk-container-apps::-webkit-scrollbar
    {
        width: 0;
        background-color: #F5F5F5;
    }

    .dk-container-apps::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #D62929;
    }
    .dk-appsStandardCols{
        width:100%;
        min-height:140px;
        background: #eee;
        border-radius: 10px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
        align-items: center;
        -webkit-justify-content: center;
        justify-content: center;
        margin-bottom:30px;
        a{
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            -webkit-justify-content: center;
            justify-content: center;
            flex-wrap: wrap;
            color: #333;
            width: 100%;
            min-height: 140px;
            text-decoration: none;
            &:hover{
                text-decoration: none;
            }
            
            .dk-appsColsIcon{
                text-align: center;
                margin-bottom: 10px;
                img{
                    width: 100%;
                    max-width: 50px;
                    transition: all .5s;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
            .PortaliconsBtn{
                button{
                    background: #f2f2f2;
                    border: none;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    border-radius: 3px;
                    margin: 0 2px;
                    outline: none;
                    color: #067185;
                }
            }
            .title{
                font-size:18px;
                text-transform: capitalize;
                font-weight: 400;
                display: block;
                text-align: center;
                width: 100%;
            }
        }
    }
    .dk_appsBgCol_1{
        background: #535255;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_2{
        background: #297483;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_3{
        background:#5c737d;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_4{
        background:#26596d;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_5{
        background:#00539c;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_6{
        background:#38607c;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_7{
        background:#4c5f59;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_8{
        background:#2b8faf;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_9{
        background:#a43b63;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_10{
        background:#a84d4d;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_11{
        background:#483252;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_12{
        background:#57496d;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_13{
        background:#2e75b6;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_14{
        background:#cf7c49;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_15{
        background:#3e4c60;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_16{
        background:#144773;
        a{
            color: #fff;
        }
    }
    .dk_appsBgCol_custom{
        background: #3e4c60;
        a{
            color: #fff;
        }
    }
}

.dk-appsStandardMain .dk-appsStandardCols a:hover .dk-appsColsIcon img{
    transition: all .5s;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
}

.dk-appsStandardMain{
    .dk-titleHead{
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
        a{
            display: inline-block;
            color: #333;
            text-transform: uppercase;
            font-size: 16px;
            border:1px solid #333;
            border-radius: 3px;
            padding:10px 0;
            margin: 0 6px;
            width: 100%;
            max-width:180px;
            text-align: center;
            font-weight: 600;
        }
    }
    .dk-container-apps{
        .col-md-3{
            flex: 0 0 20%;
            max-width: 20%;
        }
        
    }
}
.dk-appsSideMenu{
    display: inline-block;
    float: left;
    margin-right:40px;
    width: 100%;
    max-width:200px;
    min-height: 450px;
    border-radius: 5px;
    background: #2e536f;
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            list-style-type: none;
            &:first-child{
                a{
                    border-radius: 5px 5px 0 0;
                }
            }
            &:last-child{
                a{
                    border-bottom: none;
                }
            }
            a{
                display: block;
                background:#2e536f;
                color: #fff;
                padding:10px 12px;
                border-bottom:1px solid #436986;
                position: relative;
                &::after{
                    content: "\f0da";
                    display: inline-block;
                    font-family: 'Font Awesome 5 Free';
                    font-size: 18px;
                    font-weight: bold;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    right:12px;
                    color: #fff;
                    top: 11px;
                }
                &:hover{
                    background:#EAEAEA;
                    transition: all 0.5s;
                    color: #333;
                    border-bottom:1px solid #436986;
                }
            }
            a.active{
                background:#EAEAEA;
                color: #333;
                border-bottom:1px solid #436986;
                position: relative;
                &::after{
                    content: "\f0da";
                    display: inline-block;
                    font-family: 'Font Awesome 5 Free';
                    font-size: 18px;
                    font-weight: bold;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    position: absolute;
                    right:12px;
                    color: #333;
                    top: 11px;
                }
            }
        }
    }
}

.dk-appsStandardMain.dk-1000-container{
    max-width: 1080px;
}