.dk-submMenuMain{
    padding: 0 20px;
    .dk-subMenu{
        width: 100%;
        max-width: 1600px;
        margin: auto;
        ul{
            list-style: none;
            display: flex;
            width: 100%;
            padding: 0;
            background:#EAEAEA;
            border: none;
            border-radius: 0 0 5px 5px;
            margin: 0;
            li{
                list-style-type: none;
                width: 100%;
                max-width: 9.07%;
                text-align: center;
                border-right:1px solid #afafaf;
                &:first-child{
                    max-width:9.15%;
                }
                a{
                    background: transparent;
                    color: #333;
                    border: none;
                    padding: 10px 10px;
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    transition: all 0.5s;
                    font-size: 14px;
                    &:hover{
                        text-decoration: none;
                        background: #fff;
                        transition: all 0.5s;
                        color: #333;
                    }
                }
                .sub-active{
                    background: #fff;
                    color: #333;
                }
            }
        }
    }
}