.dk-faqMain{
    padding:35px 20px;
    .dk-faqHead{
        position: relative;
        .dk-faqleft-btn{
            a{
                background: #f2f2f2;
                border: 1px solid #ccc !important;
                text-transform: uppercase;
                font-size: 14px;
                outline: none;
                display: inline-block;
                height: 35px;
                line-height: 35px;
                color: #333;
                padding: 0 15px;
                margin-right: 8px;
                border-radius: 3px;
                font-weight: 500;
                &:hover{
                    background: #ddd;
                    border-color: #ddd;
                    transition: all 0.5s;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        h3.title{
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            margin-top:0;
            text-align: center;
            margin-bottom: 0;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
        }
        .dk-faqleft{
            position: relative;
            width: 100%;
            max-width: 160px;
            &::after{
                content: "\f002";
                display: inline-block;
                font-family: 'Font Awesome 5 Free';
                font-size: inherit;
                color: rgb(139, 139, 139);
                font-weight: bold;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                right:8px;
                top: 9px;
            }
            input{
                background-color: #f5f2f2;
                border-radius: 0;
                border: 1px solid #ddd !important;
                box-shadow: none !important;
                font-weight: 100;
                height: 35px;
                padding-right: 30px;
            }
        }
        .dk-faqRight{
            a{
                background: #f2f2f2;
                border: 1px solid #ccc !important;
                text-transform: uppercase;
                font-size: 14px;
                outline: none;
                display: inline-block;
                height: 35px;
                line-height: 35px;
                color: #333;
                padding: 0 15px;
                margin-right: 10px;
                border-radius: 3px;
                font-weight: 500;
                &:hover{
                    background: #ddd;
                    border-color: #ddd;
                    transition: all 0.5s;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    
    .dk-faqTableMain{
        margin-top:4px;
        height: calc(100vh - 216px);
        .table-responsive{
            height: 100%;
            table{
                tr{
                    th{
                        color: #fff;
                        background-color: #26597d;
                        font-weight:600;
                        text-align: center;
                        font-size: 14px;
                        border: 0;
                        border-right: 1px solid #dee2e6;
                        border-top: 1px solid #dee2e6;
                        vertical-align: middle;
                    }
                    td{
                        padding: 5px 10px;
                        font-size: 13px;
                        vertical-align: middle;
                        background-color: #F5F5F5;
                        border-right: 1px solid #c5c5c5;
                        .dk-faqAction{
                            button{
                                display: inline-block;
                                width: 35px;
                                height: 32px;
                                line-height: 32px;
                                text-align: center;
                                color: #fff;
                                border-radius: 3px;
                                border: none;
                                outline: none;
                                background: #ccc;
                                margin: 0 3px;
                                &:hover{
                                    opacity: 0.8;
                                }
                                &:first-child{
                                    background: #007bff;
                                }
                                &:last-child{
                                    background: #E2445B;
                                }
                            }
                        }
                        .dk-faqStatus{
                            .dropdown{
                                .dropdown-toggle{
                                    padding: 5px 10px;
                                    min-width: 90px;
                                    outline: none;
                                    box-shadow: none !important;
                                    &::after{
                                        display: none;
                                    }
                                }
                                .dropdown-menu{
                                    padding: 0;
                                    min-width: 90px;
                                    border-radius: 0;
                                    a{
                                        padding: 8px 10px;
                                        &:first-child{
                                            background: green;
                                            color: #fff;
                                        }
                                        &:last-child{
                                            background: #E2445B;
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                        .dk-faqEdit{
                            background: #17a2b8;
                            border: 0;
                            cursor: pointer;
                            font-weight: 600;
                            min-width: 95px;
                            display: inline-block;
                            color: #fff;
                            padding:4px 18px;
                            border-radius:3px;
                        }
                    }
                }
            }
        }
    }
    .dk-faqTableMain.dk-faqQuestion{
        table{
            tr{
                th{
                    color: #333;
                    background-color: #d9d9d9;
                    border-right: 1px solid #c5c5c5;
                    border-top: 1px solid #c5c5c5;
                }
            }
        }
    }
}

.dk-faqAddCategoryMain{
    .modal-dialog{
        width:100%;
        max-width:700px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0 auto;
        .modal-content{
            box-shadow: none;
            border:none;
            border-radius: 5px;
            .modal-header{
                background-color: #26597d;
                color: #333;
                margin-bottom: 0;
                line-height: 1.5;
                width: 100%;
                font-weight: bold;
                text-align: center;
                padding: 12px 10px;
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                .modal-title{
                    margin-bottom: 0;
                    line-height: 1.5;
                    font-size: 18px;
                    width: 100%;
                    text-align: center;
                    font-weight: bold;
                    color: #ffffff;
                }
                .close{
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    right: 15px;
                    top: 14px;
                    opacity: 1;
                    text-shadow: none;
                    color: #fff;
                    outline: none;
                }
            }
            .dk-faqAddModal-body{
                form{
                    label{
                        width: 100%;
                        margin: 0;
                        font-size: 16px;
                        &:first-child{
                            width: 190px;
                        }
                        input{
                            height: 36px;
                            border-radius: 2px;
                            background: #f5f2f2;
                            border:1px solid #ddd !important;
                            box-shadow: none !important;
                        }
                    }
                    button{
                        display: inline-block;
                        color: #fff;
                        background-color: #26597d;
                        padding:10px 20px;
                        border-radius: 3px;
                        border:none;
                        text-transform: uppercase;
                        &:hover{
                            background: #17a2b8;
                            transition: all 0.5s;
                        }
                    }
                }
            }
        }
    }
}