.crossUserFilter{
  position: relative;
  top: -10px;
  color: #000;
  right: 7px;
  font-size: 15px !important;
  cursor: pointer;
}
.dk-filterUserTabs{
  .dropdown-toggle.btn{
      background: #297483 !important;
      width:35px;
      height: 35px;
      border-radius:50%;
      border:1px solid #ddd !important;
      box-shadow: none !important;
      color: #fff;
      font-size: 15px;
      padding: 0;
      margin: 0;
      outline: none;
      &::after{
          display: none;
      }
      .table-select-user-img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
  }
  .dropdown-menu{
      min-width: auto;
      padding:5px 8px;
      transform: inherit !important;
      top: -5px !important;
      left:45px !important;
      right: inherit !important;
      background: #f2f2f2;
      &::before{
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 7.5px 10px 7.5px 0;
          border-color: transparent #ccc transparent transparent;
          line-height: 0;
          position: absolute;
          left: -10px;
          top:16px;
      }
      .dropdown-item{
          padding: 0;
          display: inline-block;
          margin: 0 3px;
          img{
              width: 35px;
              max-width: 35px;
              height: 35px;
              border-radius: 50%;
          }
      }
  }
}