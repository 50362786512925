.dk-clientsMain{
    padding:40px 20px;
    .dk-clientsHead{
        position: relative;
        h3.title{
            font-size: 22px;
            font-weight: 600;
            color: #333;
            position: absolute;
            left: 50%;
            top:3px;
            transform: translate(-50%);
            margin: 0;
        }
        .dk-clientsHead-leftPanel{
            display: flex;
            .dk-searchCols{
                width: 100%;
                max-width: 150px;
                position: relative;
                margin-right: 5px;
                &::after{
                        content: "\f002";
                        display: inline-block;
                        font-size: 15px;
                        text-rendering: auto;
                        font-weight: bold;
                        font-family: "Font Awesome 5 Free";
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        right: 8px;
                        top: 1px;
                        color: #969696;
                        height: 33px;
                        line-height: 37px;
                        padding-left: 6px;
                        border-left: 1px solid #ddd;
                }
                input{
                    border-radius: 3px;
                    height: 35px;
                    border: 1px solid #ddd !important;
                    box-shadow: none !important;
                    padding-right: 25px;
                }
            }
            .dk-clientLeft-btn{
                display: flex;
                align-items: center;
                a{
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    border-radius: 3px;
                    background: #eaeaea;
                    text-align: center;
                    line-height: 35px;
                    color: #333;
                    margin-right:5px;
                    &:hover{
                        background: #2e536f;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
                & .dk-filterUserTabs button:hover{
                    background: #2e536f !important;
                    color: #fff;
                    transition: all .5s;
                }
                & .filterbtn:hover{
                    background: #2e536f !important;
                    color: #fff;
                    transition: all .5s;
                }
            }
        }
        .dk-clientsHead-rightPanel{
            .dk-accountRightPanel-dropdown{
                margin-left: 10px;
                .dropdown{
                    .dropdown-toggle{
                        background:#eaeaea;
                        border:1px solid #ddd;
                        color: #333;
                        width: 100%;
                        min-width: 100px;
                        box-shadow: none !important;
                    }
                    .dropdown-menu{
                        min-width: 140px;
                        border-radius: 0;
                        border: 1px solid #ddd;
                        margin: 0 !important;
                        transform: inherit !important;
                        top: 34px !important;
                        right: 0 !important;
                        left: inherit !important;
                        padding: 0;
                        a{
                            display:block;
                            padding:6px 12px;
                            border-bottom: 1px solid #ddd;
                            &:hover{
                                transition: all 0.5s;
                            }
                            &:last-child{
                                border-bottom:none;
                            }
                        }
                    }
                }
            }
            .dk-accountSmallBtn{
                a{
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    border-radius:50%;
                    background: #eaeaea;
                    text-align: center;
                    line-height: 35px;
                    font-size: 14px;
                    color: #333;
                    border:1px solid #ddd;
                    &:hover{
                        transition: all 0.5s;
                        background: #2e536f;
                        border-color: #2e536f;
                        color: #fff;
                    }
                }
            }
        }
    }
    .dk-clientsTable-main{
        margin-top:5px;
        .table-responsive{
            table{
                margin-bottom: 0;
                tr{
                    th{
                        white-space: nowrap;
                        vertical-align: middle;
                        text-align: center;
                        background: #2e536f;
                        color: #fff;
                        font-weight: 600;
                        font-size: 13px;
                        border-color: #777;
                        border-bottom: none;
                        position: sticky;
                        top: 0;
                        z-index: 99;
                    }
                    td{
                        white-space: nowrap;
                        vertical-align: middle;
                        text-align: center;
                        background: #2a3340;
                        color: #e2e2e2;
                        font-size: 13px;
                        border-color: #777;
                        .dk-featuredSwitch{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            span{
                                margin: 0 8px;
                                display: inline-block;
                                position: relative;
                                top: -1px;
                            }
                        }
                        .dk-roleDropdown{
                            .dropdown-toggle{
                                width: 100%;
                                min-width: 90px;
                                box-shadow: none !important;
                                &::after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                padding: 0;
                                border-radius: 0;
                                min-width:90px;
                                border: none;
                                a{
                                    padding:6px 12px;
                                    border-bottom:none;
                                    &:nth-child(01){
                                        background: #40a250;
                                        color: #fff;
                                    }
                                    &:nth-child(02){
                                        background: #E2445B;
                                        color: #fff;
                                    }
                                    &:nth-child(03){
                                        background: #ffc107;
                                        color: #333;
                                    }
                                }
                            }
                        }
                        .dk-actionBtn{
                            a{
                                display: inline-block;
                                width: 30px;
                                height: 28px;
                                line-height:28px;
                                border-radius: 3px;
                                margin: 0 3px;
                                background: #ccc;
                                text-align: center;
                                color: #fff;
                            }
                            a.dk-viewBtn{
                                background: #ffc107;
                            }
                            a.dk-editBtn{
                                background:#007bff;
                            }
                            a.dk-deleteBtn{
                                background:#E2445B;
                            }
                        }
                        .statusBtn{
                            a{
                                display: inline-block;
                                width: 80px;
                                padding:6px 0;
                                color: #fff;
                                background: #40a250;
                                border-radius:5px;
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                        }
                        .dk-comments{
                            display: inline-block;
                            width: 30px;
                            height: 28px;
                            background: #3e9eba;
                            text-align: center;
                            line-height: 28px;
                            border-radius: 3px;
                            cursor: pointer;
                        }
                        .dk-tableUserImg{
                            img{
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// switch start

.dk-featuredSwitch input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.dk-featuredSwitch label {
	cursor: pointer;
	text-indent: -9999px;
	width: 40px;
	height:21px;
	background: white;
    border:1px solid #ccc;
	display: block;
	border-radius: 30px;
	position: relative;
}

.dk-featuredSwitch label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 3px;
	width:15px;
	height:15px;
	background: #ccc;
	border-radius: 90px;
	transition: 0.3s;
}

.dk-featuredSwitch input:checked + label {
	background: #007bff;
    border-color: #007bff;
}

.dk-featuredSwitch input:checked + label:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
    background: #fff;
}

.dk-featuredSwitch label:active:after {
	width: 40px;
}
.dk-clientLeft-btn.dk-accountManeger .dk-filterUserTabs .dropdown-toggle.btn{
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 3px;
    background: #eaeaea !important;
    text-align: center;
    line-height: 35px;
    color: #333;
    margin-right: 5px;

}
 