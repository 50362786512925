.dk-clientsMain.dk-loginsPageCon{
    padding:20px 20px;
    .dk-clientsHead{
        position: relative;
        h3.title{
            font-size: 22px;
            font-weight: 600;
            color: #333;
            position: absolute;
            left: 50%;
            top:3px;
            transform: translate(-50%);
            margin: 0;
        }
        .dk-clientsHead-leftPanel{
            display: flex;
            .dk-searchCols{
                width: 100%;
                max-width: 150px;
                position: relative;
                margin-right: 5px;
                &::after{
                        content: "\f002";
                        display: inline-block;
                        font-size: 15px;
                        text-rendering: auto;
                        font-weight: bold;
                        font-family: "Font Awesome 5 Free";
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        position: absolute;
                        right: 8px;
                        top: 1px;
                        color: #969696;
                        height: 33px;
                        line-height: 37px;
                        padding-left: 6px;
                        border-left: 1px solid #ddd;
                }
                input{
                    border-radius: 3px;
                    height: 35px;
                    border: 1px solid #ddd !important;
                    box-shadow: none !important;
                    padding-right: 25px;
                }
            }
            .dk-clientLeft-btn{
                display: flex;
                align-items: center;
                a{
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    border-radius: 3px;
                    background: #eaeaea;
                    text-align: center;
                    line-height: 35px;
                    color: #333;
                    margin-right:5px;
                    &:hover{
                        background: #2e536f;
                        color: #fff;
                        transition: all 0.5s;
                    }
                }
                & .dk-filterUserTabs button:hover{
                    background: #2e536f !important;
                    color: #fff;
                    transition: all .5s;
                }
                & .filterbtn:hover{
                    background: #2e536f !important;
                    color: #fff;
                    transition: all .5s;
                }
            }
        }
        .dk-clientsHead-rightPanel{
            .dk-accountRightPanel-dropdown{
                margin-left: 10px;
                .dropdown{
                    .dropdown-toggle{
                        background:#eaeaea;
                        border:1px solid #ddd;
                        color: #333;
                        width: 100%;
                        min-width: 100px;
                        box-shadow: none !important;
                    }
                    .dropdown-menu{
                        min-width: 140px;
                        border-radius: 0;
                        border: 1px solid #ddd;
                        margin: 0 !important;
                        transform: inherit !important;
                        top: 34px !important;
                        right: 0 !important;
                        left: inherit !important;
                        padding: 0;
                        a{
                            display:block;
                            padding:6px 12px;
                            border-bottom: 1px solid #ddd;
                            &:hover{
                                transition: all 0.5s;
                            }
                            &:last-child{
                                border-bottom:none;
                            }
                        }
                    }
                }
            }
            .dk-accountSmallBtn{
                a{
                    display: inline-block;
                    width: 35px;
                    height: 35px;
                    border-radius:50%;
                    background: #eaeaea;
                    text-align: center;
                    line-height: 35px;
                    font-size: 14px;
                    color: #333;
                    border:1px solid #ddd;
                    &:hover{
                        transition: all 0.5s;
                        background: #2e536f;
                        border-color: #2e536f;
                        color: #fff;
                    }
                }
            }
        }
    }
    .dk-toolsMain{
        .dk-toolsHead{
            position: relative;
            .dk-toolsLeft{
                .dk-toolsSearch {
                    width: 100%;
                    max-width: 170px;
                    input {
                        background-color: #f5f2f2!important;
                        border-radius: 0;
                        padding: 0 10px;
                        height: 37px;
                        border: 1px solid #ddd!important;
                        box-shadow: none!important;
                        font-size: 13px;
                    }
                }
            }
            h3.title {
                text-transform: uppercase;
                font-size: 20px;
                font-weight: 600;
                margin-top: 7px;
                margin-bottom: 0;
                position: absolute;
                left: 50%;
                -webkit-transform: translate(-50%);
                transform: translate(-50%);
                top: 0;
            }
            .dk-toolsRight{
                a {
                    margin-right: 10px;
                    height: 37px;
                    line-height: 37px;
                    color: #333;
                    padding: 0 12px;
                    font-weight: 400;
                    background: #f2f2f2;
                    border: 1px solid #ccc!important;
                    text-transform: uppercase;
                    font-size: 14px;
                    outline: none;
                    display: inline-block;
                }
                a:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .dk-clientsTable-main{
        margin-top:5px;
        .table-responsive{
            table.dk-toolsloginsTable{
                margin-bottom: 0;
                tr{
                    th{
                        color: #fff;
                        background-color: #26597d;
                        font-weight: 500;
                        text-align: center;
                        font-size: 15px;
                        border: 0;
                        border-right: 1px solid #dee2e6;
                        border-top: 1px solid #dee2e6;
                        border-bottom: none;
                        position: sticky;
                        top: 0;
                        z-index: 99;
                    }
                    td{
                        padding: 8px 10px;
                        vertical-align: middle;
                        background-color: #f5f5f5;
                        border: 1px solid #c5c5c5;
                        white-space: nowrap;
                        text-align: center;
                        font-weight: 400;
                        font-size: 14px;
                        color: #333;
                        .dk-featuredSwitch{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            span{
                                margin: 0 8px;
                                display: inline-block;
                                position: relative;
                                top: -1px;
                            }
                        }
                        .dk-roleDropdown{
                            .dropdown-toggle{
                                width: 100%;
                                min-width: 90px;
                                box-shadow: none !important;
                                &::after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                padding: 0;
                                border-radius: 0;
                                min-width:90px;
                                border: none;
                                a{
                                    padding:6px 12px;
                                    border-bottom:none;
                                    &:nth-child(01){
                                        background: #40a250;
                                        color: #fff;
                                    }
                                    &:nth-child(02){
                                        background: #E2445B;
                                        color: #fff;
                                    }
                                    &:nth-child(03){
                                        background: #ffc107;
                                        color: #333;
                                    }
                                }
                            }
                        }
                        .dk-actionBtn{
                            a{
                                display: inline-block;
                                width: 30px;
                                height: 28px;
                                line-height:28px;
                                border-radius: 3px;
                                margin: 0 3px;
                                background: #ccc;
                                text-align: center;
                                color: #fff;
                            }
                            a.dk-viewBtn{
                                background: #ffc107;
                            }
                            a.dk-editBtn{
                                background:#007bff;
                            }
                            a.dk-deleteBtn{
                                background:#E2445B;
                            }
                        }
                        .statusBtn{
                            a{
                                display: inline-block;
                                width: 80px;
                                padding:6px 0;
                                color: #fff;
                                background: #40a250;
                                border-radius:5px;
                                &:hover{
                                    text-decoration: none;
                                }
                            }
                        }
                        .dk-comments{
                            display: inline-block;
                            width: 30px;
                            height: 28px;
                            background: #3e9eba;
                            text-align: center;
                            line-height: 28px;
                            border-radius: 3px;
                            cursor: pointer;
                        }
                        .dk-tableUserImg{
                            img{
                                width: 32px;
                                height: 32px;
                                border-radius: 50%;
                            }
                        }
                    }
                }
            }
        }
    }
}

// switch start

.dk-featuredSwitch input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.dk-featuredSwitch label {
	cursor: pointer;
	text-indent: -9999px;
	width: 40px;
	height:21px;
	background: white;
    border:1px solid #ccc;
	display: block;
	border-radius: 30px;
	position: relative;
}

.dk-featuredSwitch label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 3px;
	width:15px;
	height:15px;
	background: #ccc;
	border-radius: 90px;
	transition: 0.3s;
}

.dk-featuredSwitch input:checked + label {
	background: #007bff;
    border-color: #007bff;
}

.dk-featuredSwitch input:checked + label:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
    background: #fff;
}

.dk-featuredSwitch label:active:after {
	width: 40px;
}
.dk-clientLeft-btn.dk-accountManeger .dk-filterUserTabs .dropdown-toggle.btn{
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 3px;
    background: #eaeaea !important;
    text-align: center;
    line-height: 35px;
    color: #333;
    margin-right: 5px;

}
.dk-toolsReorderModal{
    .modal-dialog{
        width: 100%;
        max-width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin:0 auto;
        .modal-content{
            border-radius:7px;
            .modal-header{
                background-color: #26597d;
                color: #333;
                margin-bottom: 0;
                width: 100%;
                font-weight: bold;
                text-align: center;
                padding:8px 10px;
                border-radius: 5px 5px 0 0;
                .modal-title{
                    text-align: center;
                    color: #fff;
                    width: 100%;
                    display: block;
                    font-weight: 600;
                    font-size: 18px;
                }
                .close{
                    color: #fff;
                    text-shadow: none;
                    padding: 0;
                    outline: none;
                    opacity: 1;
                    font-size: 24px;
                    position: absolute;
                    right: 27px;
                    top: 23px;                
                }
            }
            .dk-toolsModalbody{
                .sortable{
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    .ui-state-default{
                        border:1px solid #ccc;
                        border-bottom: none;
                        padding: 7px;
                        &:last-child{
                            border-bottom:1px solid #ccc;
                        }
                        i.fa-arrows-alt{
                            background: #6c757d;
                            padding: 5px;
                            color: #fff;
                            margin-right:8px;
                        }
                    }
                }
                form{
                    padding:10px 15px;
                    .form-group{
                        margin-bottom: 10px;
                    }
                    label{
                        width: 100%;
                        font-weight: normal;
                        font-weight: 400;
                        input{
                            border-radius: 0;
                            height: 35px;
                            background: #f2f2f2;
                            box-shadow: none !important;
                            border:1px solid #ddd !important;
                        }
                        select{
                            border-radius: 0;
                            height: 35px;
                            background: #f2f2f2;
                            box-shadow: none !important;
                            border:1px solid #ddd !important;
                        }
                        button{
                            background: #26597d;
                            border: none;
                            color: #fff;
                            text-transform: uppercase;
                            padding: 8px 25px;
                            font-weight: 500;
                            font-size: 14px;
                            outline: none;
                            cursor: pointer;
                            border-radius:5px;
                            &:hover{
                                background: #3e9eba;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
                .dk-addCliBtn {
                    display: inline-block;
                    background: #26597d;
                    color: #fff;
                    padding: 6px 18px;
                    font-size: 14px;
                    box-shadow: none;
                }
                .dk-addCliBtn:hover {
                    background: #3e9eba;
                    transition: all 0.5s;
                }
            }
        }
    }
}
.dk-toolsReorderModal{
    .modal-dialog{
        width: 100%;
        max-width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin:0 auto;
        .modal-content{
            border-radius:7px;
            .modal-header{
                background-color: #26597d;
                color: #333;
                margin-bottom: 0;
                width: 100%;
                font-weight: bold;
                text-align: center;
                padding:8px 10px;
                border-radius: 5px 5px 0 0;
                .modal-title{
                    text-align: center;
                    color: #fff;
                    width: 100%;
                    display: block;
                    font-weight: 600;
                    font-size: 18px;
                }
                .close{
                    color: #fff;
                    text-shadow: none;
                    padding: 0;
                    outline: none;
                    opacity: 1;
                    font-size: 24px;
                    position: absolute;
                    right: 27px;
                    top: 23px;                
                }
            }
            .dk-toolsModalbody{
                .sortable{
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    .ui-state-default{
                        border:1px solid #ccc;
                        border-bottom: none;
                        padding: 7px;
                        &:last-child{
                            border-bottom:1px solid #ccc;
                        }
                        i.fa-arrows-alt{
                            background: #6c757d;
                            padding: 5px;
                            color: #fff;
                            margin-right:8px;
                        }
                    }
                }
                form{
                    padding:10px 15px;
                    .form-group{
                        margin-bottom: 10px;
                    }
                    label{
                        width: 100%;
                        font-weight: normal;
                        font-weight: 400;
                        input{
                            border-radius: 0;
                            height: 35px;
                            background: #f2f2f2;
                            box-shadow: none !important;
                            border:1px solid #ddd !important;
                        }
                        select{
                            border-radius: 0;
                            height: 35px;
                            background: #f2f2f2;
                            box-shadow: none !important;
                            border:1px solid #ddd !important;
                        }
                        button{
                            background: #26597d;
                            border: none;
                            color: #fff;
                            text-transform: uppercase;
                            padding: 8px 25px;
                            font-weight: 500;
                            font-size: 14px;
                            outline: none;
                            cursor: pointer;
                            border-radius:5px;
                            &:hover{
                                background: #3e9eba;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-toolsBkValues{
    padding: 35px 20px;
    .dk-toolsValues-flex{
        display:flex;
        .dk-toolsValues-cols{
            width: 100%;
            max-width: 19%;
            .dk-toolsValueBox{
                border-right:2px solid #fff;
            }
            .dk-valueTitle{
                background-color:#d9d9d9;
                font-size:14px;
                border:1px solid #c0c0c0;
                padding:6px 12px;
                h6.subTitle{
                    margin: 0;
                }
                a{
                    color: #333;
                }
            }
            .dk-toolsValueBox{
                ul{
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    li{
                        list-style-type: none;
                        .clickBody{
                            background: #26597d;
                            color: #fff;
                            font-weight: 500;
                            border-bottom: 1px solid #fff;
                            padding:8px 12px;
                            cursor: pointer;
                        }
                        .activeTabs{
                            background:#03a786;
                            position: relative;
                            &::after{
                                content: "\f061";
                                display: inline-block;
                                font-family: "Font Awesome 5 Free";
                                font-size: 14px;
                                color: #fff;
                                font-weight: bold;
                                text-rendering: auto;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                position: absolute;
                                right: 10px;
                                top: 10px;
                            }
                        }
                    }
                }
            }
        }
        .dk-toolsValues-cols.dk-toolsLastValue{
            max-width: 23%;
            .dk-valueTitle{
                background: #03a786;
                border-color: #03a786;
                h6.subTitle{
                    color: #fff;
                }
                a{
                    color: #fff;
                }
            }
            .dk-toolsValueField{
                margin-top: 15px;
                .dk-valueFields{
                    display: flex;
                    margin-bottom:5px;
                    span.dk-handle{
                        background-color: #363f4c;
                        height:35px;
                        width:50px;
                        text-align: center;
                        line-height: 35px;
                        color: white;
                        cursor: all-scroll;
                    }
                    input{
                        height: 35px;
                        border-radius: 0;
                        border:none;
                        box-shadow: none !important;
                    }
                    span.dk-toolsValueDelete{
                        background-color:#dc3545;
                        height:35px;
                        width:50px;
                        text-align: center;
                        line-height: 35px;
                        color: white;
                        cursor: pointer;
                    }
                    span.dk-toolsValueColor{
                        background-color:#fff;
                        border:1px solid #ccc;
                        color: #333 !important;
                        height:35px;
                        width:50px;
                        text-align: center;
                        line-height: 35px;
                        color: white;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.dk-toolsReorderModal{
    .modal-dialog{
        width: 100%;
        max-width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        margin:0 auto;
        .modal-content{
            border-radius:7px;
            .modal-header{
                background-color: #26597d;
                color: #333;
                margin-bottom: 0;
                width: 100%;
                font-weight: bold;
                text-align: center;
                padding:8px 10px;
                border-radius: 5px 5px 0 0;
                .modal-title{
                    text-align: center;
                    color: #fff;
                    width: 100%;
                    display: block;
                    font-weight: 600;
                    font-size: 18px;
                }
                .close{
                    color: #fff;
                    text-shadow: none;
                    padding: 0;
                    outline: none;
                    opacity: 1;
                    font-size: 24px;
                    position: absolute;
                    right: 27px;
                    top: 23px;                
                }
            }
            .dk-toolsModalbody{
                .sortable{
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    .ui-state-default{
                        border:1px solid #ccc;
                        border-bottom: none;
                        padding: 7px;
                        &:last-child{
                            border-bottom:1px solid #ccc;
                        }
                        i.fa-arrows-alt{
                            background: #6c757d;
                            padding: 5px;
                            color: #fff;
                            margin-right:8px;
                        }
                    }
                }
                form{
                    padding:10px 15px;
                    .form-group{
                        margin-bottom: 10px;
                    }
                    label{
                        width: 100%;
                        font-weight: normal;
                        font-weight: 400;
                        input{
                            border-radius: 0;
                            height: 35px;
                            background: #f2f2f2;
                            box-shadow: none !important;
                            border:1px solid #ddd !important;
                        }
                        select{
                            border-radius: 0;
                            height: 35px;
                            background: #f2f2f2;
                            box-shadow: none !important;
                            border:1px solid #ddd !important;
                        }
                        button{
                            background: #26597d;
                            border: none;
                            color: #fff;
                            text-transform: uppercase;
                            padding: 8px 25px;
                            font-weight: 500;
                            font-size: 14px;
                            outline: none;
                            cursor: pointer;
                            border-radius:5px;
                            &:hover{
                                background: #3e9eba;
                                transition: all 0.5s;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dk-toolsBkVlueseditModal{
    .modal-dialog{
        width: 100%;
        max-width: 500px;
        margin:0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        .modal-content{
            border-radius: 5px;
            .modal-header{
                padding:8px 10px;
                background-color: #37404d;
                color: #fff;
                border-radius:4px 4px 0 0;
                .modal-title{
                    display: block;
                    width: 100%;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                }
                .close{
                    padding: 0;
                    position: absolute;
                    right: 16px;
                    top: 8px;
                    margin: 0;
                    opacity: 1;
                    text-shadow: none;
                    color: #fff;
                    font-size: 24px;
                    outline: none;
                }
            }
            .dk-toolsValuesModalBody{
                padding: 0;
                .form-group{
                    padding:15px 15px 0;
                }
            }
            .modal-body{
                .dk-toolsModalbody{
                    form{
                        label{
                            width: 100%;
                            font-size: 15px;
                            input{
                                border-radius: 0;
                                background: #f2f2f2;
                                border-color: #ddd !important;
                                box-shadow: none !important;
                            }
                        }
                        .form-footer{
                            border-top: 1px solid #ddd;
                            padding: 15px 15px;
                            display: flex;
                            justify-content:flex-end;
                            button{
                                margin-right: 10px;
                                display: inline-block;
                                padding:8px 20px;
                                border: none;
                                background: #444;
                                color: #fff;
                                border-radius: 3px;
                                &:hover{
                                    opacity: 0.8;
                                    transition: all 0.5s;
                                }
                                &:last-child{
                                    margin-right: 0;
                                }
                            }
                            button.closeBtn{
                                background: #dc3545;
                                &:hover{
                                    opacity: 0.8;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                    .nav-tabs{
                        margin-bottom: 0 !important;
                        border:none;
                        flex-wrap: nowrap;
                        .nav-item{
                            width: 100%;
                            text-align: center;
                            border-radius: 0;
                            outline: none;
                            padding:9px 0;
                            border:none !important;
                            &:nth-child(01){
                                background:#69594a;
                                color: #fff;
                            }
                            &:nth-child(02){
                                background:#363f4c;
                                color: #fff;
                            }
                            &:nth-child(03){
                                background:#dc3545;
                                color: #fff;
                            }
                        }
                    }
                    .dk-toolsValuesPanel{
                        margin-top: 15px;
                        .dk-sno{
                            display: inline-block;
                            width:40px;
                            height: 37px;
                            border:1px solid #ddd;
                            border-right: none;
                            text-align: center;
                            line-height: 35px;
                        }
                        .dk-toolsPanelSaveBtn{
                            background-color: #363f4c;
                            color: #fff !important;
                            padding:8px 15px;
                            border:none;
                            margin-top:20px;
                            margin-bottom:10px;
                        }
                        input{
                            border-radius: 0;
                            height:37px;
                            border:1px solid #ddd !important;
                            background: #f2f2f2;
                            box-shadow: none !important;
                        }
                        .dk-toolsPanel-updateIcon{
                            display: inline-block;
                            width: 45px;
                            border: 1px solid #ddd;
                            border-left: none;
                            text-align: center;
                            line-height: 35px;
                            cursor: pointer;
                            i{
                                padding: 7px;
                                text-align: center;
                                color: #fff;
                                background-color: #28a745;
                                border-color: #28a745;
                            }
                        }
                    }
                    .dk-toolsValuesPanel.dk-toolsReorderPanel{
                        .dk-sno{
                            height: auto;
                            i{
                                color: #fff;
                                background-color: #6c757d;
                                border-color: #6c757d;
                                padding: .5rem .5rem;
                            }
                        }
                        input{
                            background: #fff;
                            pointer-events: none;
                        }
                        .dk-toolsPanel-updateIcon{
                            i.fa-trash-alt{
                                color: #fff;
                                background-color: #dc3545;
                                border-color: #dc3545;
                            }
                        }
                    }
                }
            }
        }
    }
}
.dk-toolsMain{
    padding:25px 20px;
    .dk-toolsHead{
        position: relative;
        h3.title{
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            margin-top: 7px;
            margin-bottom: 0;
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            top: 0;
        }
        .dk-toolsLeft{
            .dk-toolsSearch{
                width: 100%;
                max-width: 170px;
                input{
                    background-color: #f5f2f2 !important;
                    border-radius: 0;
                    padding:0 10px;
                    height: 37px;
                    border: 1px solid #ddd !important;
                    box-shadow: none !important;
                    font-size:13px;
                }
            }
        }
        .dk-toolsRight{
            a{
                margin-right: 10px;
                height: 37px;
                line-height: 37px;
                color: #333;
                padding: 0 12px;
                font-weight: 400;
                background: #f2f2f2;
                border: 1px solid #ccc !important;
                text-transform: uppercase;
                font-size: 14px;
                outline: none;
                display: inline-block;
                &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    text-decoration: none;
                    background: #ddd;
                    transition: all 0.5s;
                }
                i{
                    display: inline-block;
                    margin-right: 3px;
                }
            }
        }
    }
    .dk-toolsTableMain{
        margin-top:8px;
        .table-responsive{
            table{
                tr{
                    th{
                        color: #fff;
                        background-color: #26597d;
                        font-weight: 500;
                        text-align: center;
                        font-size: 15px;
                        border: 0;
                        border-right: 1px solid #dee2e6;
                        border-top: 1px solid #dee2e6;
                    }
                    td{
                        padding:8px 10px;
                        vertical-align: middle;
                        background-color: #F5F5F5;
                        border-right: 1px solid #c5c5c5;
                        white-space: nowrap;
                        text-align: center;
                        font-weight: 400;
                        font-size: 14px;
                        .dk-toolsStatus{
                            .dropdown-toggle{
                                background: #1b6867;
                                border-color: #1b6867 !important;
                                color: #fff;
                                border-radius: 5px;
                                padding: 0.30rem .56rem;
                                min-width: 80px;
                                outline: none;
                                box-shadow: none !important;
                                &::after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                border-radius: 0;
                                border: none;
                                padding: 0;
                                min-width: 120px;
                                a{
                                    font-size: 14px;
                                    text-transform: capitalize;
                                    padding: 0.45rem 0.56rem;
                                }
                                a.dk-activeBtn{
                                    background: #1b6867;
                                    color: #fff;
                                }
                                a.dk-inActiveBtn{
                                    background:#dc3545;
                                    color: #fff;
                                }
                            }
                        }
                        .dk-toolsDepartment{
                            .dropdown-toggle{
                                background: #009a9a;
                                border-color: #009a9a !important;
                                color: #fff;
                                border-radius: 5px;
                                padding: 0.30rem .56rem;
                                min-width: 172px;
                                outline: none;
                                box-shadow: none !important;
                                &::after{
                                    display: none;
                                }
                            }
                            .dropdown-menu{
                                border-radius:0;
                                border: none;
                                padding: 0;
                                min-width: 174px;
                                a{
                                    font-size: 14px;
                                    text-transform: capitalize;
                                    padding: 0.45rem 0.56rem;
                                }
                                a.dk-activeBtn{
                                    background: #1b6867;
                                    color: #fff;
                                }
                                a.dk-inActiveBtn{
                                    background:#dc3545;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .dk-toolsTablePagination{
            ul{
                margin: 0;
                padding: 0;
                li{
                    list-style-type: none;
                    &:last-child{
                        a{
                            border-right: 1px solid #ddd;
                            border-radius:0 0 3px 3px;
                        }
                    }
                    &:first-child{
                        a{
                            border-radius: 3px 0 0 3px;
                        }
                    }
                    a{
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        background: #f2f2f2;
                        border: 1px solid #ddd;
                        border-right: none;
                        color: #333;
                        font-weight:500;
                        text-align: center;
                        &:hover{
                            background: #3e9eba;
                            color: #fff;
                            text-decoration: none;
                            transition: all 0.5s;
                            border-color: #3e9eba;
                        }
                    }
                    a.active{
                        background: #3e9eba;
                        border-color: #3e9eba;
                        color: #fff;
                    }
                }
            }
        }
    }
}
